import { useEffect, useState } from "react";
import "./App.css";
function App() {
  const [data, setData] = useState([]);
  const [online, setOnline] = useState();
  function indexedDB(data) {
    const DB = new Promise((resolve, reject) => {
      const request = window.indexedDB.open("data", 2);
      request.onupgradeneeded = () => {
        request.result.createObjectStore("list", { autoIncrement: true });
      };
      request.onsuccess = () => resolve(request.result);
      request.onerror = (e) => reject(e);
    });

    const migrate = (list) => {
      DB.then((db) => {
        const store = db.transaction("list", "readwrite").objectStore("list");
        list?.forEach((e) => store.add(e));
      });
    };
    const getList = () => {
      DB.then((db) => {
        const request = db.transaction("list").objectStore("list").getAll();
        request.onsuccess = (e) => setData(e.target.result);
      });
    };
    if (data) {
      migrate(data);
      getList();
    }
    getList();
  }
  useEffect(() => {
    indexedDB();
    setOnline(navigator.onLine ? "online" : "offline");
    window.addEventListener("offline", () => {
      setOnline("offline");
    });
    window.addEventListener("online", () => {
      setOnline("online");
    });
  }, []);

  return (
    <div>
      <div className="inputWrapper">
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
              indexedDB([{ img: reader.result }]);
            });
            reader.readAsDataURL(e.target.files[0]);
          }}
        />
      </div>
      <div className="box">
        {data.map((e, i) => {
          return <img key={i} width="400" src={e.img} alt="" />;
        })}
      </div>
      {online === "online" ? (
        <div className="online">You are online</div>
      ) : (
        <div className="offline">You are offline</div>
      )}
    </div>
  );
}
export default App;
